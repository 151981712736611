import React from 'react';
import './assets/css/main.css';
import { loadReCaptcha } from 'react-recaptcha-google';
import Header from './components/Header';
import Home from './screens/Home';
import Features from './screens/Features';
import AboutUs from './screens/AboutUs';
import Products from './screens/Products';
import Portfolio from './screens/Portfolio';
import Contact from './screens/Contact';
import Localization from './screens/Localization';
import Footer from './components/Footer';
import Swal from 'sweetalert2';


class App extends React.Component {
  componentDidMount() {
    loadReCaptcha();
    Swal.fire({
      title: 'Comunicado importante!',
      html: 'Nossos telefones mudaram, que tal anotar os novos números? <br> (17) 2122 7776 <br> (17) 2122 7778 <br><br> Agradecemos a compreensão.',
      type: 'info'
    })
  }

  render() {
  
    return (
      <div>
        <Header />
        <Home />
        <Features />
        <AboutUs />
        <Products />
        <Portfolio />
        <Contact />
        <Localization />
        <Footer />
      </div>
    );
  }
}

export default App;
