import React from 'react';

function Home() {
  return (
    <section id="inicio">
      <div className="background">
        <div className="message">
          A tecnologia
          <br />
          transformando
          {' '}
          <b>vidas</b>
          <span>...</span>
          <br />
          <a href="#porque-nos-escolher" className="button">...</a>
        </div>
      </div>
    </section>
  );
}

export default Home;
