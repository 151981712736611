import React from 'react';
import '../assets/css/hamburger.css';

class Header extends React.Component {
  componentDidMount() {
    const menuButton = document.getElementsByClassName('hamburger-menu')[0];
    menuButton.addEventListener('click', () => {
      menuButton.classList.toggle('is-active');
      document.getElementsByClassName('menu-nav')[0].classList.toggle('is-active');
    });
  }

  render() {
    function closeMenu() {
      document.getElementsByClassName('hamburger-menu')[0].classList.remove('is-active');
      document.getElementsByClassName('menu-nav')[0].classList.remove('is-active');
    }
    return (
      <header id="header">
        <div className="logo" />
        <button className="hamburger-menu hamburger hamburger--spin" type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
          Abrir menu mobile
        </button>
        <nav className="menu-nav">
          <ul className="font-nunito">
            <li>
              <a href="#inicio" onClick={() => closeMenu()}>PAGINA INICIAL</a>
            </li>
            <li>
              <a href="#porque-nos-escolher" onClick={() => closeMenu()}>PORQUE ESCOLHER A CITRINO</a>
            </li>
            <li>
              <a href="#sobre-nos" onClick={() => closeMenu()}>SOBRE NÓS</a>
            </li>
            <li>
              <a href="#produtos" onClick={() => closeMenu()}>PRODUTOS</a>
            </li>
            <li>
              <a href="#portfolio" onClick={() => closeMenu()}>PORTFÓLIO</a>
            </li>
            <li>
              <a href="#fale-conosco" onClick={() => closeMenu()}>FALE CONOSCO</a>
            </li>
            <li>
              <a href="#localizacao" onClick={() => closeMenu()}>ONDE ESTAMOS</a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}


export default Header;
