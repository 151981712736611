import React from 'react';
import Slider from 'react-slick';
import geliusImage from '../assets/images/gelius.png';
import rmImage from '../assets/images/rm.png';

import '../assets/css/fontawesome.min.css';

function Portfolio() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section id="portfolio">
      <div className="portfolio">
        <Slider {...settings}>
          <li>
            <div className="picture" style={{ backgroundImage: `url(${geliusImage})` }}>
              <div className="category font-poppins">website</div>
            </div>
            <div className="item-info">
              <h1 className="font-poppins">
                Gelius móveis
              </h1>
              <p className="font-poppins">
                O novo site da empresa Gelius.<br/>
                Atualmente, a Gelius tem 32000 m² de área construída,
                às margens da rodovia Washington Luís, e conta com uma media
                de 350 colaboradores, que produzem cerca de 60.000 peças por mês.
              </p>

              <a
                href="https://gelius.com.br"
                className="button-visit font-poppins"
                target="_blank"
                rel="noopener noreferrer"
              >
                VISITAR
              </a>

              <div className="tecnologies">
                <h2 className="font-poppins">TECNOLOGIAS UTILIZADAS</h2>
                <ul className="tecnologies">
                  <li className="html"><i className="fab fa-html5"></i></li>
                  <li className="css"><i className="fab fa-css3"></i></li>
                  <li className="js"><i className="fab fa-js-square"></i></li>
                  <li className="php"><i className="fab fa-php"></i></li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="picture" style={{ backgroundImage: `url(${rmImage})` }}>
              <div className="category font-poppins">website</div>
            </div>
            <div className="item-info">
              <h1 className="font-poppins">
                Recauchutadora Mirassol
              </h1>
              <p className="font-poppins">
                O novo portal da Recauchutadora Mirassol.<br/>
                A RM Pneus sempre busca oferecer produtos com alto padrão de qualidade e segurança.<br/>
                Por isso, mantemos um alto controle de qualidade em todos os nossos processos produtivos.
              </p>

              <a
                href="https://rmpneus.com.br"
                className="button-visit font-poppins"
                target="_blank"
                rel="noopener noreferrer"
              >
                VISITAR
              </a>

              <div className="tecnologies">
                <h2 className="font-poppins">TECNOLOGIAS UTILIZADAS</h2>
                <ul className="tecnologies">
                  <li className="html"><i className="fab fa-html5"></i></li>
                  <li className="css"><i className="fab fa-css3"></i></li>
                  <li className="js"><i className="fab fa-js-square"></i></li>
                  <li className="angular"><i className="fab fa-angular"></i></li>
                  <li className="node"><i className="fab fa-node-js"></i></li>
                </ul>
              </div>
            </div>
          </li>

        </Slider>
      </div>
      <div className="navigation">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </section>
);
}

export default Portfolio;
