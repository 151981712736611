import React from 'react';

function AboutUs() {
  return (
    <section id="sobre-nos">
      <div className="background">
        <ul>
          <li>
            <h1 className="font-poppins">
              Transformando
              {' '}
              <b>ideias em realidade</b>
            </h1>
            <p className="font-nunito">
              Todo grande negócio começa como uma simples ideia.
              <br />
              Nós tambem começamos assim, em 1994 surgiu a ideia de criar
              uma empresa de tecnologia que resolvesse com excelencia os problemas dos clientes.
              <br />
              Hoje nossos sistemas economizam tempo e dinheiro dos nossos clientes, fazendo pra eles toda a parte
              massiva do trabalho.
            </p>
          </li>
          <li>
            <h1 className="font-poppins">
              Nossa
              {' '}
              <b>visão</b>
            </h1>
            <p className="font-nunito">
              A gente sabe que quando nossos clientes crescem nós também crescemos. <br/>
              É por esse e por outros motivos que nos esforçamos tanto para entregar sistemas que geram resultados! <br/>
              Em simples palavras a gente quer crescer e ajudar o seu negócio a crescer tambem.
            </p>
          </li>
          <li>
            <h1 className="font-poppins">
              Os próximos
              {' '}
              <b>passos...</b>
            </h1>
            <p className="font-nunito">
              Estamos sempre de olho nas mais novas tecnologias, buscando entregar aos nossos clientes produtos cada vez mais rapidos e faceis de utilizar. <br/>
              Cada nova tecnologia é um novo desafio e diferente dos concorrentes nós nunca paramos no tempo!
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default AboutUs;
