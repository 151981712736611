import React from 'react';

function Localization() {
  return (
    <section id="localizacao">
      <div className="background">
        <h1 className="font-raleway">
          Onde
          {' '}
          <b>estamos</b>
        </h1>

        <iframe
          title="Google Maps"
          className="map"
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ5fwVL3avvZQRM6pRchDPYiI&key=AIzaSyDfHvRG0wqm3fUamOytbZAuIxWMgyz-aok"
          allowFullScreen
        />

        <div className="address font-poppins">
          Rua São Pedro<br/>
          <b>1819</b><br/>
          Centro, Mirassol - SP
        </div>
      </div>
    </section>
  );
}

export default Localization;
