import React from 'react';

function Features() {
  return (
    <section id="porque-nos-escolher">
      <div className="background">
        <div className="pokeball" />
        <h1 className="font-raleway">
          porque {' '}
          <b>nos escolher?</b>
        </h1>

        <ul className="features">
          <li className="feature">
            <span className="title font-raleway">
              ATUALIZAÇÕES
              {' '}
              <b>FREQUENTES</b>
            </span>
            <div className="separator" />
            <p className="text font-poppins">
              Nossos sites e softwares, contam com atualizações constantes
              que mantém o perfeito funcionamento dos
              sistemas e o atendimento de todas as obrigações fiscais.
            </p>
          </li>

          <li className="feature">
            <span className="title font-raleway">
              <b>MODERNIDADE</b>
            </span>
            <div className="separator" />
            <p className="text font-poppins">
              Visamos oferecer o melhor para nossos clientes,
              para que isso seja possível, nossos contribuintes estão
              sempre em busca de novas tecnologias,
              participando de cursos e conferências.
            </p>
          </li>

          <li className="feature">
            <span className="title font-raleway">
              SUPORTE
              {' '}
              <b>ESPECIALIZADO</b>
            </span>
            <div className="separator" />
            <p className="text font-poppins">
              Contamos com uma ferramenta WEB de HelpDesk que
              garante um retorno em todas as solicitações realizadas,
              ficando desnecessário a utilização do telefone,
              dessa forma nossos clientes são atendidos rapidamente e
              sem custo.
            </p>
          </li>
        </ul>

        <div className="radial-effect" />

        <a href="#sobre-nos" className="button-continue font-poppins">
          e varios outros motivos,
          <br />
          <b>
            veja
            <span className="dots">...</span>
          </b>
        </a>
      </div>
    </section>
  );
}

export default Features;
