import React from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { ReCaptcha } from 'react-recaptcha-google';

class Contact extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      name: '',
      email: '',
      text: '',
      captcha: '',
    };
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
      document.getElementById(this.captchaDemo.props.elementID).style.opacity = 0;
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  verifyCallback(recaptchaToken) {
    this.setState({ captcha: recaptchaToken });
  }

  render() {
    const sendMail = async (e) => {
      e.preventDefault();
      const {
        name, email, text, captcha,
      } = this.state;
      const sendButton = document.getElementsByClassName('button-send')[0];
      sendButton.innerHTML = 'Enviando <div class="bar"></div>';
      sendButton.disabled = true;
      sendButton.classList.add('loading');
      await axios.post('https://admcitrino.com.br/citrino-api/public/email', {
        name,
        email,
        text,
        captcha,
      })
        .then(() => {
          swal.fire({
            title: 'Bom trabalho!',
            text: 'Email enviado com sucesso!',
            type: 'success',
          });
        })
        .catch((error) => {
          swal.fire({
            title: 'Ops!',
            text: error.response.data.message,
            type: 'error',
          });
        });

      this.setState({
        name: '',
        email: '',
        text: '',
      });

      sendButton.innerHTML = 'Prosseguir <div class="bar"></div>';
      sendButton.classList.remove('loading');
      sendButton.disabled = false;
    };

    return (
      <section id="fale-conosco">
        <div className="form">
          <h1 className="font-raleway">
            Fale
            {' '}
            <b>conosco</b>
          </h1>
          <form
            onSubmit={event => sendMail(event)}
          >
            <label htmlFor="contact_name">Qual seu nome?</label>
            <input
              id="contact_name"
              type="text"
              placeholder="Digite seu nome..."
              onKeyUp={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <label htmlFor="contact_email">Seu email:</label>
            <input
              id="contact_email"
              type="email"
              placeholder="Digite seu endereço de email..."
              onKeyUp={(event) => {
                this.setState({ email: event.target.value });
              }}
            />
            <label htmlFor="contact_text">Oque tem a nos dizer?</label>
            <textarea
              id="contact_text"
              rows="10"
              placeholder="Oque tem a nos dizer?"
              onKeyUp={(event) => {
                this.setState({ text: event.target.value });
              }}
            />
            <ReCaptcha
              hl="pt-BR"
              ref={(el) => { this.captchaDemo = el; }}
              size="invisible"
              render="explicit"
              sitekey="6LdC9K4UAAAAANDBaEF81WTMUgvScoN6dpEiBFVa"
              theme="dark"
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.verifyCallback}
            />
            <button
              type="submit"
              className="button-send"
            >
              Prosseguir
              <div className="bar" />
            </button>
          </form>
        </div>
        <div className="infos">
          <h1>
            Manda uma
            <b>
              {' '}
              cartinha
            </b>
          </h1>
          <br />
          <p>
            suporte@admcitrino.com.br
          </p>

          <h1>
            Ou
            <b>
              {' '}
              liga pra gente
              <span role="img" aria-labelledby="fale-conosco">🤙</span>
            </b>
          </h1>
          <br />
          <p>
            (17) 2122 - 7776
            <br />
            (17) 2122 - 7778
          </p>
        </div>
      </section>
    );
  }
}

export default Contact;
