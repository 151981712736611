import React from 'react';
import anydesk from '../assets/images/anydesk.png';
import ammy from '../assets/images/ammy.jpg';
import winrar from '../assets/images/winrar.png';

function Footer() {
  return (
    <footer id="footer">
      <div className="downloads">
        <div className="title">DOWNLOADS</div>
        <ul className="programs">
          <li style={{ backgroundImage: `url('${anydesk}')` }}>
            <a href="https://download.anydesk.com/AnyDesk.exe" target="_blank" rel="noopener noreferrer">
              AnyDesk
            </a>
          </li>
          <li style={{ backgroundImage: `url('${winrar}')` }}>
            <a href="http://www.rarlab.com/rar/wrar571br.exe" target="_blank" rel="noopener noreferrer">
              Winrar
            </a>
          </li>
          <li style={{ backgroundImage: `url('${ammy}')` }}>
            <a href="http://www.ammyy.com/pt/downloads.html" target="_blank" rel="noopener noreferrer">
              Ammyy Admin
            </a>
          </li>
        </ul>
      </div>
      <div className="copyrights">
        desenvolvido pela melhor equipe do mundo
      </div>
    </footer>
  );
}

export default Footer;
