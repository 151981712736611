import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Products() {
  return (
    <section id="produtos">
      <div className="background">
        <div className="page-info">
          <div>
            <h1 className="font-raleway">Nossos <b>produtos</b></h1>
            <span className="description font-raleway">
              Conheça alguns de<br/>
              nossos maravilhosos produtos...
            </span>
          </div>
        </div>
        <div className="products">
          <Slider {...settings}>
            <div>
              <h1>Citrino ERP</h1>
              <p>
                O Citrino ERP é uma ferramenta gerencial completa,
                ele possúi os módulos essenciais para qualquer tipo
                de empresa como gestão de entidades, suprimentos,
                fiscal, financeira e resultados, todos os controles
                oferecidos por ele fazem com que ele seja a melhor
                opção de sistema para sua empresa...
              </p>
            </div>
            <div>
              <h1>Citrino Loja</h1>
              <p>
                O Citrino Loja é um sistema que prioriza a velocidade
                nos cadastrados, sem abrir mão do controle e consistência
                das informações, essas características somada a
                um PDV eficiente faz dele um grande
                aliado para qualquer tipo de comércio.
              </p>
            </div>
            <div>
              <h1>Web Sites</h1>
              <p>
                Desenvolvemos todos os tipos de sites, utilizando as
                tecnologias mais recentes, com isso conseguimos disponibilizar
                para os nossos clientes sites modernos e responsivos que
                podem ser utilizados perfeitamente em qualquer tipo
                de dispositivo, além disso, nós também possuímos uma
                ferramenta gerenciadora de conteúdo que permite várias
                modificações, deixando o site mais dinâmico e funcional
                permitindo a alteração de banners, noticias, produtos e etc.
              </p>
            </div>

            <div>
              <h1>Força de vendas</h1>
              <p>
                O força de vendas é um sistema web que possibiita que os
                representante ou vendedores façam pedido online mesmo
                estando fora da empresa, ele pode ser integrado com outros
                sistemas de gestão. Por ser totalmente configurável, fica
                a questão do usuário parametriza-lo da forma que melhor
                atenda o seu negócio, dessa forma é possível definir o preço
                dos itens, valor de comissão, condição de pagamento e etc. <br/>
                Com ele economizamos tempo e procedimentos que eram feitos
                manualmente, melhorando o prazo de entrega do produto para o
                cliente e dimunuindo a mão de obra para gestão dos pedidos.
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Products;
